import React, { useEffect, useState, useRef } from "react";
import { Link, graphql } from "gatsby";
import {
  LayoutSl,
  SEO2,
  AssetImage,
  LWrap2,
  LWrap,
  RecruitNews,
  Vertical,
  InterviewPic,
  CImgCard,
  WordPic,
  WordFull,
  ContainerCrumbs,
  WordPicWords,
  CInterviewJumbotron,
  CQuoteBox,
  CInterviewMedia,
  CSectTitle,
  CSchedule,
  CStars,
  CLabelCard,
  CBtnList,
} from "../../../components/_index";
import "../../../assets/_sass/page/recruit/common.scss";
import { title } from "process";
import { useMobile } from "../../../utils/use-series";
const SubPage = ({ data }: { data: any }) => {
  const isSp = useMobile();
  const frontmatter = data?.markdownRemark?.frontmatter;

  const verticalData = {
    title: <>キャリアパス・研修制度・<br />福利厚生</>,
    content: <>Career Path & Training System & <br /> Employee benefits</>,
    cla_left: "works_big_left career-double-jp",
    cla_right: "works_small_right career-double-en",
    exClass: "container_left_No1",
    exClassWord: "container_left_sp",
  };
  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "キャリアパス・研修制度・福利厚生",
      src: "/recruit/careerpath/",
    },
  ];

  const interviewData = [
    {
      src: "/assets/images/recruit/career/career.png",

      alt: "",
      title: [
        {
          title: "新入社員研修",
        },
      ],

      subtitle: [
        {
          title:
            "全国の同期と集まり、ホテリエとしての基礎を学んでいただきます。",
        },
        {
          title:
            "オリエンテーション、ビジネスマナー、ユニバーサルマナー、おもてなし英会話、メイクアップ、接遇ロールプレイング、各部署講義、総支配人講話 等",
        },
      ],
    },
    {
      src: "/assets/images/recruit/career/career02.png",
      alt: "",
      title: [
        {
          title: "フォローアップ研修（1～3年目）",
        },
      ],
      subtitle: [
        {
          title:
            "若年層育成の為の研修です。業務の棚卸しや自身の成長の振り返りを行いつつ、次のステップ・キャリアについて考えます。",
        },
      ],
    },
    {
      src: "/assets/images/recruit/career/career03.png",
      alt: "",
      title: [
        {
          title: "中堅リーダー研修",
        },
      ],
      subtitle: [
        {
          title:
            "中堅リーダー層（30～40代中心）向けの研修です。研修後は半年間のビジネスプログラムが用意されており、等級に合わせたビジネス知識・スキル習得のための講座をオンラインで受講します。",
        },
      ],
    },
    {
      src: "/assets/images/recruit/career/career04.png",
      alt: "",
      title: [
        {
          title: "その他等級別研修",
        },
      ],
      subtitle: [
        {
          title: "管理職研修、ミドルマネジメント研修、部長研修、GM研修等",
        },
        {
          title: "キャリアステップに応じた研修があります。",
        },
      ],
    },
    {
      src: "/assets/images/recruit/career/career05.png",
      alt: "",
      title: [
        {
          title: "ニューフェイスアワード",
        },
      ],
      subtitle: [
        {
          title:
            "当社で働く若手ホテリエとしてふさわしい見本となり、かつ具体的な提案や活動によりホテル運営に貢献した入社3年目までのスタッフが推薦により表彰されます。",
        },
      ],
    },
    {
      src: "/assets/images/recruit/career/career06.png",
      alt: "",
      title: [
        {
          title: "6STARS～人財グランプリ～",
        },
      ],
      subtitle: [
        {
          title:
            "ありたい人財像「6つの力」を発揮したスタッフを表彰する制度。自薦他薦を問わず誰でも応募が可能で、それぞれの力の大賞は全社員投票により決定します。",
        },
      ],
    },
    {
      src: "/assets/images/recruit/career/career07.png",
      alt: "",
      title: [
        {
          title: "調理コンクール",
        },
      ],
      subtitle: [
        {
          title:
            "複数の調理スタッフが、社内外のさまざまなコンクールに出場し活躍しています。",
        },
        {
          title: (
            <>
              <br />
            </>
          ),
        },
        {
          title:
            "・第47回技能五輪国際大会（フランス・リヨン）西洋料理部門「敢闘賞」",
        },
        {
          title:
            "・フランス料理最優秀見習い料理人選抜コンクール 第41回「ヴィアンド賞」・第42回「総合３位」",
        },
        {
          title: "・第15回青年調理士中国料理コンクール「銅賞」",
        },
        {
          title: "・第33回全国日本料理コンクール 現代日本料理部門「入賞」",
        },
        {
          title: "・社内クリスマススイーツコンテスト　等",
        },
      ],
    },
    {
      src: "/assets/images/recruit/career/career08.png",
      alt: "",
      title: [
        {
          title: "自己啓発支援制度",
        },
      ],
      subtitle: [
        {
          title:
            "自己研鑽の支援のため、300以上の講座やe- learning、通信教育等の受講費用を補助。通学の必要があり会社の承認を得た場合は、短時間勤務をすることも可能です。",
        },
      ],
    },
    {
      src: "/assets/images/recruit/career/career09.png",
      alt: "",
      title: [
        {
          title: "資格取得支援制度",
        },
      ],
      subtitle: [
        {
          title:
            "専門知識習得やスキル向上の支援のため、試験合格者の受講費用を補助。",
        },
      ],
      pbstatus:true,
    },
  ];
  const interviewData2 = [
    {
      src: "/assets/images/recruit/career/career10.png",
      alt: "",
      title: [
        {
          title: "クロストレーニング",
        },
      ],
      subtitle: [
        {
          title:
            "グループホテル間の交流と学びのため、約1カ月間他ホテルでの業務を経験し視野を広げる制度。",
        },
      ],
    },
    {
      src: "/assets/images/recruit/career/career11.png",
      alt: "",
      title: [
        {
          title: "女性活躍　男性育休　",
        },
      ],
      subtitle: [
        {
          title:
            "■女性活躍推進法・次世代育成支援の一環として、「女性のキャリアとワークライフバランス」をテーマに各エリアで女性社員の座談会を開催。さまざまな形でキャリアを積む先輩社員と意見を交わす取り組み。",
        },
        {
          title:
            "■育児・介護休業法改正対策の一環として、男性の育児休業取得推進を目的としたパネルディスカッションを開催。専任講師や育休を取得した男性社員が、法制度や体験談を広く社内に伝えました。",
        },
        {
          title: "※今後もキャリア・育児・介護等のセミナーを開催予定です。",
        },
      ],
    },
    {
      src: "/assets/images/recruit/career/career12.png",
      alt: "",
      title: [
        {
          title: "SDGs",
        },
      ],
      subtitle: [],
      jumpurl: "https://www.royalparkhotels.co.jp/about/sdgs/",
    },
    {
      src: "/assets/images/recruit/career/career13.png",
      alt: "",
      title: [
        {
          title: "健康経営",
        },
      ],
      subtitle: [],
      jumpurl: "https://www.royalparkhotels.co.jp/rphs/health/",
    },
  ];
  return (
    <LayoutSl isKv={false}>
      <SEO2 title={frontmatter?.title} description={frontmatter?.description} isTop={false} />
      <section className="mb-5 pb-5 recruit-font">
        <div className=" new_main new_main_career ">
          <ContainerCrumbs data={crumbsData} />
          <div className="container_main container_main-1-2 pb-15 top-career-article">
            <Vertical data={verticalData} />
            <div className="container_right container_right_careed">
              <div className="career_right">
                <AssetImage
                  src={`/assets/images/recruit/career/career${isSp ? "_sp" : ""
                    }.png`}
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="u_grey mb-5 recruit-font">
        <div className=" new_main u_grey new_main_career ">
          <div className="container_main_career_path u_grey">
            <div className="path_top mt-5">
              <p className="top1 mb-5-sp cl-career-sp">キャリアパス</p>
              <p className="top2 mt-3 mb-3-sp">雇用体系</p>
            </div>
            {/* 1 */}
            <div className="career_path_left mt-3">
              <p className="fs-16-30 ls-40 fs-14-28-sp">
                職種をまたいだ異動の有無、全国転勤の有無によって、３つの職群を設定しています。それぞれのライフスタイルやキャリア志向に合わせ、働き方を選択することが可能です。
              </p>
              <p className="fs-16-30 ls-40 fs-14-28-sp">
              【エリア運営職】異動はエリア内に限定され、各ホテル内で主に接遇・調理・管理業務を担っていただきます
              </p>
              <p className="fs-16-30 ls-40 fs-14-28-sp">
              【ナショナル運営職】社内試験を経て転換し、活躍の場を全国に広げることができます。業務内容はエリア運営職と変わりません。
              </p>
              <p className="fs-16-30 ls-40 fs-14-28-sp">
              【総合職】社内試験を経て転換。勤務地はナショナル運営職と同じく全国ですが、接遇のみならず本社業務に携わる等、経営者育成の為幅広い業務を経験いただきます
              </p>
              <p className="fs-16-30 ls-40 fs-14-28-sp">
                ※新卒入社の方は原則エリア運営職からのスタート（採用年により総合職の募集あり）となります。
              </p>
            </div>
            <div className="career_path_right mt-3-sp  mb-5-sp">
              <CImgCard
                data={[
                  {
                    img: {
                      src: `/assets/images/recruit/career/career_table${isSp ? "_sp" : ""}.png`,
                      alt: "",
                      largeSrc: `/assets/images/recruit/career/career_table_big.png`
                    },
                  },
                ]}
              />
            </div>
            {/* <div>
                <div>
                <AssetImage
                  src={`/assets/images/recruit/career/search.png`}
                  alt=""
                  loading="lazy"
                />
                </div>
                <div>拡大する</div>

            </div> */}
          </div>
        </div>
      </section>

      {/* 研修制度 */}
      <section className="mb-5 pb-5 recruit-font">
        <div className=" new_main new_main_career  p05-sp">
          <div className="container_main_training">
            <div className="fs-40-32 mb-5 mt-3  fs-28-30-sp mb-3-sp cl-career-sp">研修制度</div>
            <div className="fs-32-32 mb-5 mt-2 fs-24-32-sp mb-3-sp">入社前後の流れ</div>

            <div >
              <p className="fs-16-30 fs-14-28-sp">
                全国の同期と集まり、ホテリエとしての基礎を学んでいただきます。
              </p>
            </div>

            <div className="fs-16-30 mb-5  mb-5-sp fs-14-28-sp">
              人事部（採用担当）と人財開発部（研修担当）のスタッフが、入社前後の皆さまをサポートいたします。
            </div>
            <table className="career_training">
              {isSp ? (
                <>
                  <tr className="fs-14-30 ls-40 u_grey hpx-48">
                    <th
                      className="table_th fs-14-30  ls-40 fw-b"
                      style={{ width: "30%" }}
                    >
                      時期
                    </th>
                    <th
                      className="table_th fs-14-30 ls-40"
                      colSpan={2}
                    // style={{ width: "15%" }}
                    >
                      内容
                    </th>
                  </tr>
                  <tr className="fs-14-30 ls-40 hpx-100">
                    <td className="table_th fs-14-30 ls-40">～3月</td>
                    <td className="table_th fs-14-28 ls-40" colSpan={2}>
                      <div>
                        内定後
                        <br />
                        内定式、懇親会（全体／エリア別）、配属ホテル通知、入社手続き説明会
                      </div>
                    </td>
                  </tr>
                  <tr className="fs-14-30 ls-40 hpx-100">
                    <td className="table_th fs-14-30  ls-40">4月前半</td>
                    <td className="table_th fs-14-28 ls-40" colSpan={2}>
                      <div>
                        入社後
                        <br />
                        新入社員導入研修
                        <br />
                        オリエンテーション、ビジネスマナー、ユニバーサルマナー、おもてなし英会話、メイクアップ、身嗜み、接遇ロールプレイング、業務内容説明、制度説明、総支配人講話
                        等
                      </div>
                    </td>
                  </tr>
                  <tr className="fs-14-30 ls-40 hpx-100">
                    <td className="table_th">4月後半～</td>
                    <td className="table_th fs-14-28 ls-40" colSpan={2}>
                      <div>
                        配属後
                        <br />
                        各本配属先のホテルにてジョブトレーニング <br />
                        1年目終了時にフォローアップ研修や懇親会を実施（希望者にはフォロー面談があります）
                      </div>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr className="fs-16-30 ls-40 u_grey hpx-48">
                    <th
                      className="table_th fs-16-30  ls-40 fw-b"
                      style={{ width: "10%" }}
                    >
                      時期
                    </th>
                    <th
                      className="table_th fs-16-30 ls-40"
                      style={{ width: "15%" }}
                    >
                      内容
                    </th>
                    <th className="table_th fs-16-30 ls-40"></th>
                  </tr>
                  <tr className="fs-16-30 ls-40 hpx-100">
                    <td className="table_th fs-16-30 ls-40">～3月</td>
                    <td className="table_th fs-16-30 ls-40">内定後</td>
                    <td className="table_th fs-16-30 ls-40">
                      内定式、懇親会（全体／エリア別）、配属ホテル通知、入社手続き説明会
                    </td>
                  </tr>
                  <tr className="fs-16-30 ls-40 hpx-100">
                    <td className="table_th fs-16-30  ls-40">4月前半</td>
                    <td className="table_th fs-16-30 ls-40">
                      入社後
                      <br className="u_pc" />
                      新入社員導入研修
                    </td>
                    <td className="table_th fs-16-30 ls-40">
                      オリエンテーション、ビジネスマナー、ユニバーサルマナー、おもてなし英会話、メイクアップ、身嗜み、接遇ロールプレイング、業務内容説明、制度説明、総支配人講話
                      等
                    </td>
                  </tr>
                  <tr className="fs-16-30 ls-40 hpx-100">
                    <td className="table_th">4月後半～</td>
                    <td className="table_th fs-16-30 ls-40">配属後</td>
                    <td className="table_th fs-16-30 ls-40">
                      各本配属先のホテルにてジョブトレーニング <br />
                      1年目終了時にフォローアップ研修や懇親会を実施（希望者にはフォロー面談があります）
                    </td>
                  </tr>
                </>
              )}
            </table>
          </div>
        </div>
      </section>

      {/* 入社15年後までの研修 */}
      <section className="mb-5 mmb-5 u_grey recruit-font">
        <div className=" new_main u_grey new_main_career">
          <div className="container_main_repair u_grey  ">
          

            <div className="career_icon_top">
              <CImgCard
                data={[
                  {
                    img: {
                      src: `/assets/images/recruit/career/career_table2${isSp ? "_sp" : ""}.png`,
                      alt: "",
                      largeSrc: `/assets/images/recruit/career/career_table2_big.png`
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </section>

      {/* 人財育成（研修・表彰） */}
      <section className="mb-5 mmb-10 recruit-font">
        <div className=" new_main new_main_career pb-30">
          <div className={`container_main_training ${!isSp ? "p-30" : ""}`}>
            <div className="fs-32-32 mb-5 mt-5 fs-28-30-sp cl-career-sp">
              人財育成（研修・表彰）
            </div>
            <InterviewPic
              data={interviewData}
              exClassSubTitle="fs-14-28-sp"
              exClassTitle="fs-20-32-sp"
              exClass="container_main_training_title_width mb-30"
              exImgClass="mt-5-sp"
            />
          </div>
        </div>
      </section>
      {/*  */}
      {/* 社内の取り組み */}
      <section className="mb-5 mt-5 u_grey recruit-font">
        <div className=" new_main new_main_career u_grey">
          <div className={`container_main_training ${!isSp ? "p-30" : ""}`}>
            <div className="fs-32-32 mb-5 mt-10 cl-career-sp">社内の取組</div>
            <InterviewPic
              data={interviewData2}
              exClass="right_interview2"
              exClassSubTitle="fs-14-28-sp"
              exClassTitle="fs-20-32-sp"
              exImgClass="mt-5-sp"
            />
          </div>
        </div>
      </section>

      {/* 社内の取り組み */}
      <section className="mb-5 mt-5  mmb-10 recruit-font">
        <div className=" new_main new_main_career" id="fromFaq">
          <div className="container_main_training pb-5-sp">
            <div className="fs-32-32 mb-5 mt-5 fs-28-30-sp  mb-5-sp cl-career-sp">
              福利厚生・各種制度
            </div>
            <div className="fs-24-32 mb-3 fs-24-32-sp mb-3-sp">休日・休暇</div>
            <div className="mb-5-sp">
              <li className="fs-16-30 ls-40 fs-14-28-sp">
                <span className="square">■</span>年間休日…110日/118日（職種により異なります）
              </li>
              <li className="fs-16-30 ls-40 fs-14-28-sp">
                <span className="square">■</span>年次有給休暇…4月入社初年度10日付与/最高20日/翌年度まで繰越/半日単位の取得可
              </li>
              <li className="fs-16-30 ls-40 fs-14-28-sp">
                <span className="square">■</span>積立休暇…失効する未消化の有給休暇を60日まで積み立てることができます。（使用事由：私傷病、小学校3年生までの子の看護・育児、家族の看護・介護、不妊治療）
              </li>
              <li className="fs-16-30 ls-40 fs-14-28-sp">
                <span className="square">■</span>特別有給休暇…使用事由：結婚や忌引、転勤や配偶者の出産ほか
              </li>
            </div>
            <div className="fs-24-32 mt-5 mb-3 fs-24-32-sp mb-5-sp">福利厚生</div>
            <div className="mb-5 mb-5-sp">
              <li className="fs-16-30 ls-40 fs-14-28-sp">
                各種社会保険加入、退職金制度、グループホテル宿泊割引制度、福利厚生団体サービス加入、三菱地所グループ従業員持株会、確定拠出年金、財産形成貯蓄、団体生命保険割引制度
                等
              </li>
            </div>
            <div className="fs-24-32 mb-3 fs-24-32-sp mb-3-sp">ファミリーサポート</div>
            <div className="">
              <li className="fs-16-30 ls-40 fs-14-28-sp">
                <span className="square">■</span>産前産後休暇…産前6週間産後8週間
              </li>
              <li className="fs-16-30 ls-40 fs-14-28-sp">
                <span className="square">■</span>育児休業…子が3歳になった後の3月末日まで、男女共に取得可
              </li>
              <li className="fs-16-30 ls-40 fs-14-28-sp">
                <span className="square">■</span>育児短時間勤務…子が小学校3年生になった後の3月末日まで。1日5時間半を下回らない範囲で15分単位で短縮可
              </li>
              <li className="fs-16-30 ls-40 fs-14-28-sp">
                <span className="square">■</span>子の看護休暇…子が小学校3年生になった後の3月末日まで、時間単位で取得可
              </li>
              <li className="fs-16-30 ls-40 fs-14-28-sp">
                <span className="square">■</span>介護休業…通算93日の範囲内で3回まで
              </li>
              <li className="fs-16-30 ls-40 fs-14-28-sp">
                <span className="square">■</span>介護短時間勤務…対象家族一人につき、最初の利用開始の日から3年の間で2回。1日5時間半を下回らない範囲で15分単位で短縮可
              </li>
              <li className="fs-16-30 ls-40 fs-14-28-sp">
                <span className="square">■</span>介護休暇…対象の家族が一人の場合は5日、二人以上は一年度につき10日まで。半日単位および時間単位で取得可
              </li>
            </div>
          </div>
        </div>
      </section>
    </LayoutSl>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
